import { template as template_d5a491d64d914dc4a047281dc4a7fd9e } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllTagsSectionLink = template_d5a491d64d914dc4a047281dc4a7fd9e(`
  <SectionLink
    @linkName="all-tags"
    @content={{i18n "sidebar.all_tags"}}
    @route="tags"
    @prefixType="icon"
    @prefixValue="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllTagsSectionLink;
