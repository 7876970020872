import { template as template_447fef44e89e497cbf2a293a3163e950 } from "@ember/template-compiler";
const FKControlMenuContainer = template_447fef44e89e497cbf2a293a3163e950(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
