import { template as template_5df854e3ddba49ff9e0887056f177b4b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_5df854e3ddba49ff9e0887056f177b4b(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
