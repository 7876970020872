import { template as template_3f1175af061d46b7a3367ef4bc8edadb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_3f1175af061d46b7a3367ef4bc8edadb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
