import { template as template_389149880ba642a580b6e27f17d70fdf } from "@ember/template-compiler";
const FKText = template_389149880ba642a580b6e27f17d70fdf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
